<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_service') }}</strong>
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pt-2">
                        <div class="row m-0 align-items-center">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" type="text" id="name" v-model="service.name" :placeholder="translate('name')" />
                            </div>
                            <div class="col-md p-0">
                                <b-list-group-item :disabled="service.service_type === 's_appointment' || service.service_type === 's_streaming'" class="pl-1 pt-0 pb-0" :class="service.log_training ? 'bg-light' : ''">
                                    <q-checkbox :label="translate('log_training')" flat color="primary" emit-value :id="'service.log_training-' + service.log_training" :val="service.log_training" v-model="service.log_training" />
                                </b-list-group-item>
                                <b-list-group-item :disabled="service.service_type === 's_appointment' || service.service_type === 's_streaming'" class="pl-1 pt-0 pb-0" :class="service.requires_subscription ? 'bg-light' : ''">
                                    <q-checkbox :label="translate('require_subscription')" flat color="primary" emit-value :id="'service.requires_subscription' + service.requires_subscription" :val="service.requires_subscription" v-model="service.requires_subscription" />
                                </b-list-group-item>
                            </div>
                        </div>
                        <div class="row m-0 pl-3">
                            <div class="col-md p-0">
                                <small class="text-uppercase">{{ translate('service_type') }}</small>
                                <q-radio color="primary" :label="translate('schedule')" v-model="service.service_type" @input="scheduleServiceSettings()" :val="'s_schedule'" />
                                <q-radio color="primary" :label="translate('appointment')" v-model="service.service_type" @input="appointmentServiceSettings()" :val="'s_appointment'" />
                                <q-radio color="primary" :label="translate('streaming')" v-model="service.service_type" @input="streamingServiceSettings()" :val="'s_streaming'" />
                            </div>
                            <div class="col-md p-0">
                                <small class="text-uppercase">{{ translate('providers_list') }}</small>
                                <q-radio color="primary" selected :label="translate('unavailable')" v-model="service.providers_list" :val="'unavailable'" />
                                <template v-if="service.service_type === 's_schedule'">
                                    <q-radio color="primary" :disabled="service.service_type !== 's_schedule'" :label="translate('viewable')" v-model="service.providers_list" :val="'viewable'" />
                                    <q-radio color="primary" :disabled="service.service_type !== 's_schedule'" :label="translate('selectable')" v-model="service.providers_list" :val="'selectable'" />
                                </template>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div v-if="!service.requires_subscription" class="col-md p-0">
                                <q-input dense square color="primary" @wheel="$event.target.blur()" type="number" id="basic_price" v-model.number="service.base_price" step="0.01" :placeholder="translate('basic_price')">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                            <div v-if="service.service_type !== 's_appointment'" class="col-md p-0">
                                <q-input dense square color="primary" @wheel="$event.target.blur()" type="number" id="subscription_price" v-model.number="service.subscription_price" step="0.01" :placeholder="translate('subscription_price')">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                        </div>
                        <div>
                            <q-input :placeholder="translate('description')" v-model="service.description" square dense color="primary" type="textarea" rows="2" />
                        </div>
                        <div class="pl-3 pr-3 pb-2">
                            <small class="text-uppercase">{{ translate('images') }}</small>
                            <b-form-file type="file" name="selectedFiles" ref="selectedFiles" multiple @change="onFilesSelected" v-model="selectedFiles" />
                        </div>
                        <q-slide-transition>
                            <div>
                                <div class="pl-3 font-weight-bold">
                                    {{ translate('providers') }}
                                </div>
                                <b-list-group-item class="list-group-item-accent-info pt-1">
                                    <div class="font-weight-bold mt-1">{{ translate('included_providers') }} ({{ service_included_providers.length }})</div>
                                    <template v-if="service_included_providers && service_included_providers.length">
                                        <q-item class="bordered-item" clickable ripple v-for="(provider,i) in service_included_providers" :key="'included-provider-' + i + provider.id">
                                            <q-item-section avatar>
                                                <q-avatar class="shadow-2">
                                                    <img v-if="provider && provider.image_link" :src="provider.image_link">
                                                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                                                </q-avatar>
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ provider.name }}</q-item-label>
                                            </q-item-section>
                                            <q-item-section side>
                                                <q-btn dense icon="delete" @click.stop.prevent="deleteServiceProviderFromIncluded(provider, 'provider')" flat color="danger" />
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                    <q-separator />
                                    <div>
                                        <div class="p-t2 mt-2 mb-2 text-primary bg-light">
                                            <q-icon name="add" />&nbsp;
                                            {{translate('add_provider')}}
                                        </div>
                                        
                                        <div v-if="!workers.length || workers.length === 0" class="text-muted">{{ translate('no_results') }}</div>
                                        <div v-else class="text-muted">{{ translate('results_found') }}: {{ workers.length }}</div>

                                        <q-input class="search-input mb-0" @input="isTypingWorker = true" v-model="searchWorker" id="filternameitem" type="text" :placeholder="translate('find_worker')" :dense="true">
                                            <template v-slot:prepend>
                                                <q-icon name="search" />
                                            </template>
                                            <template v-slot:append>
                                                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddWorkerPanel" flat dense color="dark" no-caps :label="translate('new_worker')" icon="add" />
                                            </template>
                                        </q-input>
                                        <div class="items-container list-container-scroll vh-31" :class="'list_wraper-service-' + service.id">
                                            <q-item tag="label" class="list-item full-width avatar-red-hover" clickable ripple v-for="(worker,i) in workers" :key="'list-item-' + i + '-' + worker.id">
                                                <q-item-section avatar>
                                                    <q-avatar class="shadow-2">
                                                        <img v-if="worker && worker.image_link" :src="worker.image_link">
                                                        <i v-else class="fas fa-user fa-1x text-muted"></i>
                                                    </q-avatar>
                                                </q-item-section>
                                                <q-item-section>
                                                    <q-item-label>
                                                        {{ worker.name }}
                                                    </q-item-label>
                                                </q-item-section>
                                                <q-item-section side>
                                                    <q-btn icon="add" @click.stop.prevent="includeWorker(worker)" dense flat color="primary" />
                                                </q-item-section>
                                            </q-item>
                                        </div>
                                    </div>
                                </b-list-group-item>
                            </div>
                        </q-slide-transition>
                        <infinite-loading @infinite="getWorkers" ref="infiniteLoading" :identifier="'to-include-workers-service-' + service.id + '-' +infWorkers" slot="append" />
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerService">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    QSlideTransition
} from 'quasar'
import AddWorker from '../../../business/employees/forms/AddEmployee'

export default {
    name: 'AddService',
    props: ['selected_service_type'],
    components: {
        QSlideTransition
    },
    computed: {
        service_type_options: function () {
            return [
                    {
                        label: this.translate('schedule'),
                        value: 's_schedule'
                    },
                    {
                        label: this.translate('appointment'),
                        value: 's_appointment'
                    },
                    {
                        label: this.translate('streaming'),
                        value: 's_streaming'
                    }
                ]
        },
    },
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            workers: [],
            page_workers: 1,
            state: {},
            infWorkers: +new Date(),
            isTypingWorker: false,
            searchWorker: '',
            service_included_providers: [],
            service: {
                service_type: this.selected_service_type ? this.selected_service_type : 's_schedule',
                name: null,
                log_training: false,
                requires_subscription: false,
                appointment_service: false,
                base_price: null,
                subscription_price: null,
                description: '',
                providers_list: 'unavailable',
            },
            msg: null,
            selectedFiles: null,
            checked: null,
        }
    },
    watch: {
        searchWorker: _.debounce(function () {
            this.isTypingWorker = false
        }, 200),
        isTypingWorker: function (value) {
            if (!value) {
                this.resetMainListWorkers()
            }
        }
    },
    created: function () {
        if (this.selected_service_type !== null && this.selected_service_type === 's_streaming') {
            this.streamingServiceSettings()
        }
        if (this.selected_service_type !== null && this.selected_service_type === 's_appointment') {
            this.appointmentServiceSettings()
        }
        if (this.selected_service_type !== null && this.selected_service_type === 's_schedule') {
            this.scheduleServiceSettings()
        }
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showAddWorkerPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddWorker
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        resetCheckboxesTrueFalseValues: function () {
            // QCheckboxes need true or false values and not 1 or 0 to work properly.
            if (this.service.requires_subscription && this.service.requires_subscription === 1) {
                this.service.requires_subscription = true
            }
            if (this.service.requires_subscription && this.service.requires_subscription === 0) {
                this.service.requires_subscription = true
            }
            if (this.service.log_training && this.service.log_training === 1) {
                this.service.log_training = true
            }
            if (this.service.log_training && this.service.log_training === 0) {
                this.service.log_training = false
            }
        },
        scheduleServiceSettings: function () {
            this.service.providers_list = 'unavailable'
            this.service.log_training = false
            this.service.requires_subscription = false
        },
        appointmentServiceSettings: function () {
            this.service.providers_list = 'unavailable'
            this.service.log_training = false
            this.service.requires_subscription = false
        },
        streamingServiceSettings: function () {
            this.service.log_training = false
            this.service.requires_subscription = true
            this.service.providers_list = 'unavailable'
        },
        onFilesSelected: function (event) {
            this.selectedFiles = event.target.files[0]
        },
        setUpCheckboxesNumericValues: function () {
            if (this.service.requires_subscription === true) {
                this.service.requires_subscription = 1
            } else {
                this.service.requires_subscription = 0
            }
            if (this.service.requires_subscription === true && this.service.service_type !== 's_appointment') {
                this.service.requires_subscription = 1
            }
            if (this.service.requires_subscription === 1) {
                this.service.base_price = this.service.subscription_price
            }
            if (this.service.service_type === 's_appointment') {
                this.service.subscription_price = this.service.base_price
            }
            if (this.service.log_training === true) {
                this.service.log_training = 1
            } else {
                this.service.log_training = 0
            }
            if (this.service.log_training === true && this.service.service_type === 's_schedule') {
                this.service.log_training = 1
            }
        },
        registerService: function () {
            this.setUpCheckboxesNumericValues()

            const formData = new FormData()
            if (this.selectedFiles) {
                this.selectedFiles.map((file, index) => {
                    formData.append(`file${index}`, file)
                })
            }
            if (this.service.name) {
                formData.set('name', this.service.name)
            }
            if (this.service.service_type !== null) {
                formData.set('service_type', this.service.service_type)
            }
            if (this.service.log_training !== null) {
                formData.set('log_training', this.service.log_training)
            }
            if (this.service.requires_subscription !== null) {
                formData.set('requires_subscription', this.service.requires_subscription)
            }
            if (this.service.appointment_service !== null) {
                formData.set('appointment_service', this.service.appointment_service)
            }
            if (this.service.base_price) {
                formData.set('base_price', this.service.base_price)
            }
            if (this.service.providers_list !== null) {
                formData.set('providers_list', this.service.providers_list)
            }
            if (this.service.subscription_price) {
                formData.set('subscription_price', this.service.subscription_price)
            }
            if (this.service.description) {
                formData.set('description', this.service.description)
            }

            var worker_ids = []
            if (this.service_included_providers && this.service_included_providers.length) {
                this.service_included_providers.forEach(element => {
                    worker_ids.push(element.id)
                })
            }
            if (worker_ids && worker_ids.length) {
                formData.set('worker_ids', worker_ids.join(','))
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }
            axios.post(baseUrl + 'services', formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_service_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })

                        // QCheckboxes need true or false values and not 1 or 0 to work properly.
                        this.resetCheckboxesTrueFalseValues()
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        },
        resetMainListWorkers: function () {
            this.page_workers = 1
            this.workers = []
            this.infWorkers++
        },
        getWorkers: function ($state) {
            var url = baseUrl + 'workers?page=' + this.page_workers + '&items_per_page=' + this.$items_per_page
            if (this.searchWorker) {
                url += '&search=' + this.searchWorker
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.workers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            array['main_image_link'] = entity.main_image_link ? baseUrl + entity.main_image_link : null

                            return array
                        }))
                        if (response.data.next_page === true) {
                            this.page_workers++
                            $state.loaded()
                        } else {
                            $state.complete()
                            if (this.streaming_included_items && this.streaming_included_items.length) {
                                this.streaming_included_items.map(included_id => {
                                    if (this.workers.filter((item) => {
                                            item.id === included_id
                                        })) {
                                        // Find index of specific object using findIndex method,
                                        var objIndex = this.items.findIndex((obj => obj.id === included_id))
                                        // and remove the element from the items array.
                                        this.items.splice(objIndex, 1)
                                    }
                                })
                            }
                        }
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        includeWorker: function (item) {
            this.service_included_providers.push(item)
            this.deleteFromTOIncludeList(item.id)
            return false
        },
        addTOToIncludeList: function (item) {
            this.workers.push(item)
            return false
        }, 
        deleteFromTOIncludeList: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.workers.findIndex((obj => obj.id === id))
            // and remove the element from the workers array.
            this.workers.splice(objIndex, 1)
        },
        deleteServiceProviderFromIncluded: function (item, item_type) {
            if (item_type === 'provider') {
                var objIndex = this.service_included_providers.findIndex((obj => obj.id === item.id))
                this.service_included_providers.splice(objIndex, 1)
                this.addTOToIncludeList(item)
            }
            return false
        },
        addProvidersToList: function (items) {
            this.service_included_providers = items && items.length ? items : []
            return false
        }, 
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
